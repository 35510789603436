import * as client_hooks from '../../src/lib/hooks.client.ts';

export { matchers } from './client-matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97')];

export const server_loads = [0];

export const dictionary = {
	"/": [~2],
	"/advertise": [~4],
	"/binds": [~5],
	"/binds/converter": [~7],
	"/binds/generator": [~8],
	"/binds/say-bind": [~9],
	"/binds/[bind]": [~6],
	"/blocked": [~10],
	"/callouts": [~11],
	"/callouts/[map]": [~12],
	"/codes": [~13],
	"/codes/[code_type]": [~14],
	"/commands": [~15],
	"/commands/categories": [~17],
	"/commands/categories/[category]": [~18],
	"/commands/[command]": [~16],
	"/contact": [~19],
	"/crosshair-generator": [~20],
	"/extension": [~21],
	"/guides": [~22],
	"/guides/[article]": [~23],
	"/launch-options": [~24],
	"/ranks": [~25],
	"/ranks/premier": [~27],
	"/ranks/premier/[season]": [~28],
	"/ranks/premier/[season]/[region]": [~29],
	"/ranks/premier/[season]/[region]/[player]": [~30],
	"/ranks/[rank]": [~26],
	"/skins": [~31],
	"/skins/agents": [~34],
	"/skins/agents/[category]": [~35],
	"/skins/agents/[category]/[slug]": [~36],
	"/skins/articles": [~37],
	"/skins/articles/[article]": [~38],
	"/skins/autograph-capsules": [~39],
	"/skins/autograph-capsules/[slug]": [~40],
	"/skins/best-deals": [~41],
	"/skins/cases": [~42],
	"/skins/cases/[slug]": [~43],
	"/skins/cases/[slug]/[specialType]": [~44],
	"/skins/collectible-capsules": [~45],
	"/skins/collectible-capsules/[slug]": [~46],
	"/skins/collectibles": [~47],
	"/skins/collectibles/[slug]": [~48],
	"/skins/collections": [~49],
	"/skins/collections/[slug]": [~50],
	"/skins/colors": [~51],
	"/skins/finder": [~52],
	"/skins/gifts": [~53],
	"/skins/gifts/[slug]": [~54],
	"/skins/giveaways": [~55],
	"/skins/gloves": [~56],
	"/skins/gloves/[type]": [~57],
	"/skins/gloves/[type]/[slug]": [~58],
	"/skins/graffiti-boxes": [~59],
	"/skins/graffiti-boxes/[slug]": [~60],
	"/skins/graffitis": [~61],
	"/skins/graffitis/[slug]": [~62],
	"/skins/keys": [~63],
	"/skins/keys/[slug]": [~64],
	"/skins/music-kit-boxes": [~65],
	"/skins/music-kit-boxes/[slug]": [~66],
	"/skins/music-kits": [~67],
	"/skins/music-kits/[slug]": [~68],
	"/skins/passes": [~69],
	"/skins/passes/[slug]": [~70],
	"/skins/patch-packs": [~71],
	"/skins/patch-packs/[slug]": [~72],
	"/skins/patches": [~73],
	"/skins/patches/[slug]": [~74],
	"/skins/skin-trading-sites": [~75],
	"/skins/skin-trading-sites/[section]": [~76],
	"/skins/souvenir-packages": [~77],
	"/skins/souvenir-packages/[slug]": [~78],
	"/skins/sticker-capsules": [~79],
	"/skins/sticker-capsules/[slug]": [~80],
	"/skins/stickers": [~81],
	"/skins/stickers/colors": [~84],
	"/skins/stickers/[slug]": [~82],
	"/skins/stickers/[slug]/[page]": [~83],
	"/skins/tags": [~85],
	"/skins/tags/[slug]": [~86],
	"/skins/tools": [~87],
	"/skins/tools/[slug]": [~88],
	"/skins/tournaments": [~89],
	"/skins/tournaments/[slug]": [~90],
	"/skins/tournaments/[slug]/[page]": [~91],
	"/skins/weapons": [~92],
	"/skins/weapons/families": [~96],
	"/skins/weapons/families/[slug]": [~97],
	"/skins/weapons/[type]": [~93],
	"/skins/weapons/[type]/[weapon]": [~94],
	"/skins/weapons/[type]/[weapon]/[slug]": [~95],
	"/skins/[page]": [~32],
	"/skins/[page]/[colorPage]": [~33],
	"/[content_page]": [~3]
};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};